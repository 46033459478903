import camelcase from 'lodash/camelCase';
import transform from 'lodash/transform';
import { LooseObject } from './LooseObject';

const mapArrayKeys = <T extends object>(object: T[], cb: (key: string) => string) => {
    const newArray = [] as object[];
    object.forEach((item: T) => {
        let newValue: T;
        if (Array.isArray(item)) {
            newValue = mapArrayKeys(item, cb) as T;
        } else if (typeof item === 'object') {
            
            newValue = mapAllKeys(item, cb) as T;
        } else {
            newValue = item;
        }
        newArray.push(newValue);
    });
    return newArray;
};

const mapAllKeys = <T extends object>(object: T, cb: (key: string) => string) => {
    
    return transform<any, LooseObject>(
        object,
        (result: LooseObject, value: Object, key: string) => {
            if (Array.isArray(value)) {
                
                result[`${cb(key)}`] = mapArrayKeys(value, cb);
            } else if (value instanceof Object && !(value instanceof Date)) {
                
                result[`${cb(key)}`] = mapAllKeys(value, cb);
            } else {
                
                result[`${cb(key)}`] = value;
            }
        },
        {}
    );
};

export const mapKeysToCamelCase = <T extends object>(object: T): LooseObject => {
    return mapAllKeys(object, (key: string) => camelcase(key));
};
