import { Call } from "@twilio/voice-sdk";
import { Task } from "twilio-taskrouter";
import { FlexCall } from "~/modules/FlexCall/FlexCall";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { TaskRouterImpl } from "~/packages/taskrouter/TaskRouterImpl";
import { TaskRouter } from "~/packages/taskrouter/TaskRouter";
import { canHold, getVoiceReservation, isLiveCall, isOutboundCallTask } from "~/modules/actions/ActionUtils";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { CbmImpl } from "~/packages/cbm/CbmImpl";
import type { Cbm } from "~/packages/cbm/Cbm";

export class FlexCallImpl implements FlexCall {
    readonly #taskRouter: TaskRouter;

    readonly #cbm: Cbm;

    readonly #logger: Logger;

    readonly #call: Call;

    constructor(ctx: ContextManager, call: Call) {
        this.#taskRouter = ctx.getInstanceOf(TaskRouterImpl);
        this.#cbm = ctx.getInstanceOf(CbmImpl);
        this.#logger = getLogger(ctx)(LoggerName.Call);
        this.#logger.debug("FlexCall constructed");
        this.#call = call;
    }

    get call(): Call {
        return this.#call;
    }

    toggleMute() {
        return this.#call.mute(!this.#call.isMuted());
    }

    async disconnect(): Promise<void> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "disconnect: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservation = getVoiceReservation(worker);

        if (!reservation || !reservation.task) {
            const errorMsg = `disconnect: voice task not found`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!this.#call) {
            const errorMsg = `disconnect: no active call`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            this.#call.disconnect();
            return Promise.resolve();
        } catch (error) {
            const errorMsg = `disconnect: Failed to disconnect a call with taskSid: ${reservation.task.sid}`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async hold(holdMusicUrl: string = "", holdMusicMethod: string = "GET"): Promise<Task> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "hold: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservation = getVoiceReservation(worker);

        if (!reservation) {
            const errorMsg = `hold: voice reservation not found`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (reservation && !canHold(reservation, worker)) {
            const errorMsg = `hold: Cannot hold call with taskSid: ${reservation?.task?.sid}`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            return await reservation?.task?.updateParticipant({
                hold: true,
                ...(holdMusicUrl && { holdUrl: holdMusicUrl }),
                holdMethod: holdMusicMethod
            });
        } catch (error) {
            const errorMsg = `hold: Error holding call with taskSid: ${reservation?.task?.sid}`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async unhold(): Promise<Task> {
        const worker = this.#taskRouter.worker;

        if (!worker) {
            const errorMsg = "unhold: worker is not initialized";
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const reservation = getVoiceReservation(worker);

        if (!reservation) {
            const errorMsg = `unhold: voice reservation not found`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (reservation && !canHold(reservation, worker)) {
            const errorMsg = `unhold: Cannot unhold call with taskSid: ${reservation?.task?.sid}`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        try {
            return await reservation?.task?.updateParticipant({
                hold: false
            });
        } catch (error) {
            const errorMsg = `unhold: Error unholding call with taskSid: ${reservation?.task?.sid}`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }
    }

    async isOnHold(): Promise<boolean> {
        const worker = this.#taskRouter.worker;

        const reservation = getVoiceReservation(worker);

        if (!reservation) {
            const errorMsg = `isOnHold: voice reservation not found`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        if (!isLiveCall(reservation) && !isOutboundCallTask(reservation.task)) {
            const errorMsg = `isOnHold: voice reservation is not live outbound call`;
            this.#logger.error(errorMsg);
            return Promise.reject(new FlexSdkError(ErrorCode.SDK, { severity: ErrorSeverity.Error }, errorMsg));
        }

        const participants = await this.#cbm.getParticipantsByTask(reservation?.task);

        return participants.some(
            (participant) => participant.type === "customer" && JSON.parse(participant.mediaProperties?.hold)
        );
    }
}
