import { Supervisor, Workspace } from "twilio-taskrouter";
import type { Device } from "@twilio/voice-sdk";
import { ClientOptions } from "~/modules/client";
import { SessionOptions } from "~/modules/session";
import type { LoggerOptions } from "~/modules/logger";
import { getDefaultLoggerOptions } from "~/modules/logger/LoggerFactory/defaultLogger";
import { TelemetryOptions } from "~/modules/telemetry";
import { AccountConfigOptions } from "~/modules/config";
import { getDefaultSessionOptions } from "~/modules/session/SessionOptions/defaultSessionOptions";
import { getDefaultTelemetryOptions } from "~/modules/telemetry/TelemetryOptions/getDefaultTelemetryOptions";
import { getDefaultAccountConfigOptions } from "~/modules/config/AccountConfigOptions/getDefaultAccountConfigOptions";
import { getDefaultDataClientOptions } from "~/modules/dataClient/getDefaultDataClientOptions";
import type { DataClientOptions } from "~/modules/dataClient";
import { getDefaultTaskRouterOptions } from "~/packages/taskrouter/getDefaultTaskRouterOptions";
import type { TaskRouterOptions } from "~/packages/taskrouter/TaskRouterOptions";


export class ClientOptionsStore implements ClientOptions {
    readonly #session: SessionOptions;

    readonly #logger: LoggerOptions;

    #telemetry: TelemetryOptions;

    readonly #accountConfig: AccountConfigOptions;

    
    
    #region: string;

    
    
    #regionNonFlex: string;

    #appName: string;

    #appVersion: string;

    #worker?: Supervisor;

    #workspace?: Workspace;

    #voiceDevice?: Device;

    #origin?: string;

    #disableDataClient?: boolean;

    #dataClientOptions: DataClientOptions;

    #taskRouterOptions: TaskRouterOptions;

    constructor() {
        this.#session = getDefaultSessionOptions();
        this.#logger = getDefaultLoggerOptions();
        this.#telemetry = getDefaultTelemetryOptions();
        this.#accountConfig = getDefaultAccountConfigOptions();
        this.#dataClientOptions = getDefaultDataClientOptions();
        this.#taskRouterOptions = getDefaultTaskRouterOptions();
    }

    get session(): SessionOptions {
        return this.#session;
    }

    get logger(): LoggerOptions {
        return this.#logger;
    }

    get telemetry(): TelemetryOptions {
        return this.#telemetry;
    }

    set telemetry(telemetry: TelemetryOptions) {
        this.#telemetry = telemetry;
    }

    get accountConfig(): AccountConfigOptions {
        return this.#accountConfig;
    }

    get region(): string {
        return this.#region;
    }

    set region(value: string) {
        this.#region = value;
    }

    get regionNonFlex(): string {
        return this.#regionNonFlex;
    }

    set regionNonFlex(value: string) {
        this.#regionNonFlex = value;
    }

    get appName(): string {
        return this.#appName;
    }

    set appName(value: string) {
        this.#appName = value;
    }

    get appVersion(): string {
        return this.#appVersion;
    }

    set appVersion(value: string) {
        this.#appVersion = value;
    }

    get workspace(): Workspace | undefined {
        return this.#workspace;
    }

    set workspace(value: Workspace | undefined) {
        this.#workspace = value;
    }

    get worker(): Supervisor | undefined {
        return this.#worker;
    }

    set worker(value: Supervisor | undefined) {
        this.#worker = value;
    }

    get voiceDevice(): Device | undefined {
        return this.#voiceDevice;
    }

    set voiceDevice(value: Device | undefined) {
        this.#voiceDevice = value;
    }

    get dataClientOptions(): DataClientOptions {
        return this.#dataClientOptions;
    }

    set dataClientOptions(value: DataClientOptions) {
        this.#dataClientOptions = value;
    }

    get taskRouterOptions(): TaskRouterOptions {
        return this.#taskRouterOptions;
    }

    set taskRouterOptions(value: TaskRouterOptions) {
        this.#taskRouterOptions = value;
    }

    get origin(): string {
        return this.#origin || "headless";
    }

    set origin(value: string) {
        this.#origin = value;
    }

    get disableDataClient(): boolean {
        return this.#disableDataClient || false;
    }

    set disableDataClient(value: boolean | undefined) {
        this.#disableDataClient = value;
    }
}

let instance: ClientOptionsStore;
export const getClientOptionsStoreInstance: () => ClientOptionsStore = () => {
    instance = instance || new ClientOptionsStore();
    return instance;
};
