import { ApolloClient, ApolloLink, from, InMemoryCache, split } from "@apollo/client/core";
import { SchemaLink } from "@apollo/client/link/schema";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import type { IResolvers, TypeSource } from "@graphql-tools/utils";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { DataClientError } from "../error";
import { getGatewaySchema } from "../schemaStitching";
import { buildRegionalHost } from "~/utils/regionUtil";




export interface DataClientOptions {
    connectToDevTools?: boolean;
    localSchema?: { typeDefs: TypeSource; resolvers: IResolvers | IResolvers[] };
    errorLink?: ApolloLink;
    customSchema?: { typeDefs: TypeSource; resolvers: IResolvers | IResolvers[] };
}




export type GetToken = () => Promise<string> | string;




export interface CreateDataClientOptions extends DataClientOptions {
    region?: string;
}




export const createDataClient = async (tokenOrGetToken: string | GetToken, options?: CreateDataClientOptions) => {
    try {
        const region = buildRegionalHost(options?.region || "");
        const graphQLSubscriptionUri = `wss://event-bridge${region}.twilio.com/graphql-subscriptions`;
        const graphQLApiUri = `https://preview${region}.twilio.com/Flex/graphql`;

        const tokenIsFunction = typeof tokenOrGetToken === "function";
        const token = tokenIsFunction ? await (tokenOrGetToken as GetToken)() : (tokenOrGetToken as string);

        const gatewaySchema = await getGatewaySchema(graphQLApiUri, token, options?.customSchema, options?.localSchema);

        if (!gatewaySchema) {
            return null;
        }

        const links = [];
        const schemaLink = new SchemaLink({ schema: gatewaySchema });
        links.push(schemaLink);

        const authLink = setContext(async (_, { headers }) => {
            const authLinkToken = tokenIsFunction ? await (tokenOrGetToken as GetToken)() : tokenOrGetToken;
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${authLinkToken}` : ""
                }
            };
        });
        links.push(authLink);

        const wsUri = graphQLSubscriptionUri;
        const wsLink = new GraphQLWsLink(
            createClient({
                url: wsUri,
                connectionParams: async () => ({
                    authToken: `Bearer ${tokenIsFunction ? await (tokenOrGetToken as GetToken)() : tokenOrGetToken}`
                })
            })
        );

        if (options?.errorLink) {
            links.push(options.errorLink);
        }

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === "OperationDefinition" && definition.operation === "subscription";
            },
            wsLink,
            from(links)
        );

        return new ApolloClient({
            connectToDevTools: options?.connectToDevTools ?? false,
            cache: new InMemoryCache(),
            link: splitLink
        });
    } catch (error) {
        throw new Error(DataClientError.GraphqlApolloClientInitializationFailed);
    }
};
