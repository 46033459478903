import EventEmitter from "events";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Call, Device } from "@twilio/voice-sdk";
import { Workspace, Worker } from "twilio-taskrouter";
import { Client, ClientConfigType, ClientEvent } from "~/modules/client";
import { Role } from "~/modules/auth";
import { Twilsock, TwilsockEvent } from "~/modules/websocket";
import { Session, SessionEvent } from "~/modules/session";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkClient,
    TelemetrySdkEventName,
    TelemetrySdkEventSource
} from "~/modules/telemetrySdkClient";
import { Emitter, proxyEvent } from "~/modules/events";
import { getTelemetrySdkClient } from "~/modules/telemetrySdkClient/telemetrySdkClient";
import { ProfileConnectorApis } from "~/modules/profileConnector/Definitions";
import { ProfileConnectorImpl } from "~/modules/profileConnector/ProfileConnectorImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { TwilsockImpl } from "~/modules/websocket/Twilsock/TwilsockImpl";
import { AccountConfigStore } from "~/modules/config/AccountConfig/AccountConfigImpl/AccountConfigStore/AccountConfigStore";
import { VirtualAgentDataApi } from "~/modules/virtualAgentData/Definitions";
import { VirtualAgentDataImpl } from "~/modules/virtualAgentData/VirtualAgentDataImpl";
import { ActionsImpl } from "~/modules/actions/ActionsImpl";
import type { Actions } from "~/modules/actions/Actions";
import { TaskRouterImpl } from "~/packages/taskrouter/TaskRouterImpl";
import type { TaskRouter } from "~/packages/taskrouter/TaskRouter";
import type { Cbm } from "~/packages/cbm/Cbm";
import type { VoiceController } from "~/modules/voice/VoiceController";
import { CbmImpl } from "~/packages/cbm/CbmImpl";
import { VoiceControllerImpl } from "~/modules/voice/VoiceControllerImpl";
import { assertNotEmptyString } from "~/utils/assert";
import { DataClient } from "~/modules/dataClient/DataClient";
import { DataClientImpl } from "~/modules/dataClient/DataClientImpl";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { FlexCallImpl } from "~/modules/FlexCall";
import { AnalyticsImpl } from "~/modules/analytics/AnalyticsImpl";
import { AnalyticsInstance, EVENTS } from "~/modules/analytics/Analytics";
import { sendTrackingEvent } from "~/modules/actions/ActionUtils";

export class ClientImpl implements Client {
    readonly #session: Session;

    readonly #connection: Twilsock;

    readonly #logger: Logger;

    public readonly config: ClientConfigType;

    readonly #ctx: ContextManager;

    readonly #telemetrySdkClient: TelemetrySdkClient;

    readonly #emitter: Emitter;

    readonly #virtualAgentDataApi: VirtualAgentDataApi;

    readonly #actions: Actions;

    readonly #taskRouter: TaskRouter;

    readonly #profileConnector: ProfileConnectorApis;

    readonly #cbm: Cbm;

    readonly #voiceController: VoiceController;

    readonly #dataClient: DataClient;

    readonly #analytics: AnalyticsInstance;

    #isDestroyed: boolean;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#connection = ctx.getInstanceOf(TwilsockImpl);
        this.config = {
            account: ctx.getInstanceOf(AccountConfigStore)
        };
        this.#telemetrySdkClient = getTelemetrySdkClient(ctx);
        this.#logger = getLogger(ctx)(LoggerName.Client);
        this.#emitter = new EventEmitter();
        this.#virtualAgentDataApi = ctx.getInstanceOf(VirtualAgentDataImpl);
        this.#profileConnector = ctx.getInstanceOf(ProfileConnectorImpl);
        this.#actions = ctx.getInstanceOf(ActionsImpl);
        this.#taskRouter = ctx.getInstanceOf(TaskRouterImpl);
        this.#cbm = ctx.getInstanceOf(CbmImpl);
        this.#dataClient = ctx.getInstanceOf(DataClientImpl);
        this.#voiceController = ctx.getInstanceOf(VoiceControllerImpl);
        this.#analytics = ctx.getInstanceOf(AnalyticsImpl);
        this.#ctx = ctx;

        this.setupProxies();
    }

    setupProxies(): void {
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenAboutToExpire, ClientEvent.TokenAboutToExpire);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenExpired, ClientEvent.TokenExpired);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenUpdated, ClientEvent.TokenUpdated);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.ConnectionError, ClientEvent.ConnectionLost);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Connected, ClientEvent.ConnectionRestored);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Disconnected, ClientEvent.Disconnected);
        proxyEvent(this.#session, this.#emitter, SessionEvent.TokenAutoUpdateFailed, ClientEvent.TokenAutoUpdateFailed);
        proxyEvent(
            this.#session,
            this.#emitter,
            SessionEvent.TokenMaxLifetimeReached,
            ClientEvent.TokenMaxLifetimeReached
        );

        if (this.#voiceController?.voiceDevice) {
            this.#voiceController.voiceDevice.on(Device.EventName.Incoming, (call: Call) => {
                const flexCall = new FlexCallImpl(this.#ctx, call);
                this.#emitter.emit(ClientEvent.IncomingCall, flexCall);
            });
        }
    }

    async updateToken(token: string): Promise<void> {
        assertNotEmptyString(token, "token");
        const errors: FlexSdkError[] = [];

        if (this.#isDestroyed) {
            throw new FlexSdkError(
                ErrorCode.InvalidState,
                { severity: ErrorSeverity.Error },
                "Client already destroyed"
            );
        }

        const throwError = (error: Error, service: string) =>
            new FlexSdkError(
                ErrorCode.SDK,
                { severity: ErrorSeverity.Error },
                `Failed to update token on ${service}`,
                error
            );

        if (this.#session) {
            try {
                await this.#session.updateToken(token);
            } catch (error) {
                errors.push(throwError(error, "session"));
            }
        }

        if (this.#taskRouter) {
            try {
                await this.#taskRouter.updateToken(token);
            } catch (error) {
                errors.push(throwError(error, "task router"));
            }
        }

        if (this.#cbm) {
            try {
                await this.#cbm.updateToken(token);
            } catch (error) {
                errors.push(throwError(error, "cbm"));
            }
        }

        if (this.#voiceController) {
            try {
                await this.#voiceController.updateToken(token);
            } catch (error) {
                errors.push(throwError(error, "voice controller"));
            }
        }

        sendTrackingEvent(EVENTS.UpdateTokenCompleted, this.#analytics, this.#taskRouter.worker);

        if (errors.length) {
            throw new AggregateError(errors);
        }
    }

    #sendDestroyEvent = async (): Promise<void> => {
        try {
            const telemetrySdkClient = this.#telemetrySdkClient;
            const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
            await group.addEvents({
                eventName: TelemetrySdkEventName.ClientDestroyed,
                eventSource: TelemetrySdkEventSource.Client
            });
        } catch (e) {
            this.#logger.error("Failed to send telemetry destroy event", e);
        }
    };

    async destroy(): Promise<void> {
        this.#logger.debug("Client destroy called");
        if (this.#isDestroyed) {
            return;
        }

        this.#isDestroyed = true;
        await this.#sendDestroyEvent();
        await this.#session.destroy();
        await this.#voiceController?.voiceDevice?.destroy();
        this.#emitter.removeAllListeners();
        this.#dataClient.destroy();
        this.#ctx.destroy();
        this.#logger.debug("Client has been destroyed");
    }

    get roles(): Array<Role> {
        sendTrackingEvent(EVENTS.ClientRolesCompleted, this.#analytics, this.#taskRouter.worker);
        return [...this.#session.roles];
    }

    get token(): string {
        sendTrackingEvent(EVENTS.ClientTokenCompleted, this.#analytics, this.#taskRouter.worker);
        return this.#session.token;
    }

    get profileConnector(): ProfileConnectorApis {
        return this.#profileConnector;
    }

    get virtualAgentData(): VirtualAgentDataApi {
        return this.#virtualAgentDataApi;
    }

    get actions(): Actions {
        return this.#actions;
    }

    get dataClient(): ApolloClient<NormalizedCacheObject> | null {
        return this.#dataClient.client;
    }

    get worker(): Worker {
        return this.#taskRouter.worker;
    }

    get workspace(): Workspace {
        return this.#taskRouter.workspace;
    }

    addListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }
}
